[mat-button] {
  &.loading {
    position: relative;
    pointer-events: none;
    &::before {
      animation: fa-spin 2s infinite linear;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -7px 0 0 -7px;
      z-index: 1;
    }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, .7);
    }
  }
  &:active,
  &:focus {
    outline: none;
  }
}
.btn {
  &:active,
  &:focus {
    box-shadow: none !important;
    outline: none;
  }
}
