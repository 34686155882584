@import '_mixins';

mat-select {
  width: 100%;
}
.c-tsp-mat-select-panel {
  max-height: 324px !important;
  min-width: 300px !important;

  &-default {
    .mat-mdc-option {
      .default-text() !important;
    }
  }
  &-questions {
    .mat-mdc-option:hover {
      background: @lobby-button-checked-background;
      margin: 0px;
    }
  }
}
.c-tsp-mat-menu-panel {
  .mdc-list-item__primary-text {
    align-items: center;
    .default-text(@color: inherit) !important;
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    width: 100%;
  }
}
.c-tsp-mat-autocomplete-space {
  border: 1px solid @tsp-light-violet-blue;
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  border-top: 0;
  box-shadow: none !important;
}
