@import '_mixins';
@import '_variables';

.search-highlight {
  &-element {
    color: @black;

    &--cities,
    &--countries {
      background-color: @tsp-complicated-search-location;
    }
    &--keywords,
    &--lookingJob,
    &--remoteWork {
      background-color: @tsp-complicated-search-keyword;
    }
    &--tags {
      background-color: @tsp-complicated-search-tag;
    }
    &--skills {
      background-color: @tsp-complicated-search-skill;
    }
    &--specialities {
      background-color: @tsp-complicated-search-speciality;
    }
    &--sources {
      background-color: @tsp-complicated-search-source;
    }
  }
}
