@font-face {
  font-family: 'ProductSansBold';
  src: url('./../fonts/ProductSansBold.eot');
  src: url('./../fonts/ProductSansBold.eot') format('embedded-opentype'),
    url('./../fonts/ProductSansBold.woff2') format('woff2'), url('./../fonts/ProductSansBold.woff') format('woff'),
    url('./../fonts/ProductSansBold.ttf') format('truetype'),
    url('./../fonts/ProductSansBold.svg#ProductSansBold') format('svg');
}
@font-face {
  font-family: 'ProductSansRegular';
  src: url('./../fonts/ProductSansRegular.eot');
  src: url('./../fonts/ProductSansRegular.eot') format('embedded-opentype'),
    url('./../fonts/ProductSansRegular.woff2') format('woff2'), url('./../fonts/ProductSansRegular.woff') format('woff'),
    url('./../fonts/ProductSansRegular.ttf') format('truetype'),
    url('./../fonts/ProductSansRegular.svg#ProductSansRegular') format('svg');
}
/* montserrat-regular - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('./../fonts/montserrat-v24-latin_cyrillic-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./../fonts/montserrat-v24-latin_cyrillic-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}