@import '_variables';

.c-tags {
  display: block;
  clear: both;
  margin: 5px 0;
  &__item {
    color: @white;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    margin: 3px !important;
    &--skill {
      color: @black;
    }
    &:focus {
      outline: none;
    }
  }
  &__item-value {
    color: @white;
    background-color: @grey-blue;
    padding: 0 5px;
    border-radius: 12px;
    display: inline-block;
    margin-left: 5px;
  }
}