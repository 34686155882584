@import './_variables';

.dialog-title(@color: @black, @size: 1.3rem, @font-weight: 600) {
  color: @color;
  font-family: @main-font;
  font-size: @size;
  font-weight: @font-weight;
}

.faded-background() {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 90;
}

.transition(@property: all, @duration: 0.5s, @method: ease-in-out) {
  transition: @property @duration @method;
}

.muted-text(@color: @tsp-text-grey, @size: 0.8rem, @weight: unset) {
  color: @color;
  font-family: @main-font;
  font-size: @size;
}
.default-text(@color: @tsp-text-dark, @size: 0.9rem, @weight: unset, @family: @main-font) {
  color: @color;
  font-family: @family;
  font-size: @size;
  font-weight: @weight;
}
.reduce-lines(@lines: 1) {
  display: -webkit-box;
  overflow: hidden;
  word-break: break-word;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: @lines;
}
.bold-title(@color: @tsp-text-dark, @size: 1rem) {
  color: @color;
  font-family: @main-font;
  font-size: @size;
  font-weight: bold;
}
.bold-text() {
  font-family: @main-font;
  font-weight: bold;
}
.popup-title(@color: @black, @size: 2rem) {
  color: @color;
  font-family: @main-font;
  font-size: @size;
  font-weight: bold;
}

.link-button() {
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus {
    text-decoration: none !important;
  }
}
.link-text() {
  &:hover,
  &:focus {
    font-weight: bold;
    text-decoration: underline;
  }
}

.icon-button(@size: 20px, @font-size: 1rem) {
  background-color: transparent;
  border: 0;
  font-size: @font-size;
  height: @size;
  line-height: @size;
  padding: 0;
  width: @size;

  &:active,
  &:focus {
    outline: none;
  }
}
.rounded-icon-container(@color: transparent, @background: transparent, @size: 24px, @border-radius: 6px) {
  align-items: center;
  background-color: @background;
  border-radius: @border-radius;
  color: @color;
  display: flex;
  height: @size;
  justify-content: center;
  line-height: 0px;
  max-height: @size;
  max-width: @size;
  min-height: @size;
  min-width: @size;
  padding: 0;
  width: @size;
}
.initials(@size: 0.8rem) {
  color: @tsp-recruiter-initials;
  font-family: @main-font;
  font-size: @size;
  font-weight: 600;
  text-align: center;
  width: inherit;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}
.loader-block(@main-color: @tsp-control-border, @second-color: @tsp-control-border-vacancies) {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, @main-color 8%, @second-color 18%, @main-color 33%);
  background-size: 200%;
  position: relative;
}
