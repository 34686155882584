@import '_mixins';
@import '_variables';

.c-tsp-popup {
  &__title {
    .dialog-title() !important;
    margin: 15px 0 15px 15px !important;
    position: relative;
    text-align: start !important;
    &--down {
      cursor: pointer;
      white-space: nowrap;
    }
    &--after-description {
      margin-top: 70px;
    }
    &::before {
      display: none !important;
    }
  }

  &__down-image {
    width: 14px;
    transform: rotate(0);
    &-rotate {
      transform: rotate(180deg);
    }
  }
  &__down-section {
    visibility: hidden;
    position: absolute;
  }
  &__description {
    margin-top: 0 !important;
  }
  &-multiple-select {
    margin-top: 0 !important;
    @media @tablet, @xs-desktop {
      margin-top: -27px !important;
      padding-bottom: 22px;
    }
    &__label {
      .muted-text(@color: @tsp-text-dark);
      margin-bottom: 0;
      margin-left: 10px;
      &--description {
        margin-left: 15px;
      }
    }
  }
  &__loader {
    background-color: fade(@white, 55%);
    display: flex;
    margin: 0 -24px;
    position: absolute;
    width: 102%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
  }
  &__spinner {
    margin: auto;
  }
  &__fade-backdrop {
    background-color: rgba(0, 0, 0, 0.7);
  }
  &__fade-background {
    .mat-mdc-dialog-surface {
      background-color: @grey-96;
    }
  }
}
