@import '_mixins';
@import '_variables';

.ngx-toastr.c-notification {
  &.mat-mdc-snack-bar-container {
    font-size: 16px;
    padding: 20px 40px;
  }

  box-shadow: 0 0 12px @grey-60;
  color: @white;
  opacity: .8;
  border-radius: 10px;

  &:hover {
    box-shadow: 0 0 12px @black;
    opacity: 1;
    cursor: pointer;
  }

  &--error {
    background-color: @notification-red;
  }

  &--warning {
    background-color: @notification-orange;
  }

  &--success {
    background-color: @notification-green;
  }

  .mat-mdc-simple-snackbar-action {
    color: @white;
    font-weight: bold;
  }

  &--activity {
    align-items: center;
    background-color: @white;
    border-radius: 8px;
    box-shadow: 0 5px 23px 0 rgba(0, 0, 0, .06);
    color: @grey-33;
    padding: 16px 24px;
    width: 520px;

    &:hover {
      box-shadow: 0 5px 23px 0 rgba(0, 0, 0, .26);
    }

    .toast-close-button {
      color: @grey-33;
      right: -12px;
      top: -16px;
    }
  }
}

.toast-container {
  z-index: 2147483648;
}

.c-notification-event {
  display: flex;
  flex-flow: row wrap;

  &__config-container {
    margin-top: -10px;
    text-align: right;
    width: 100%;
  }
  &__config {
    color: @grey-25;
  }
  &__image-container {
    height: 64px;
    width: 64px;
    margin-right: 16px;
  }
  &__image {
    border-radius: 100%;
    height: 64px;
    object-fit: contain;
    width: 64px;
  }
  &__data {
    color: @grey-25;
    font-family: 'ProductSansRegular', sans-serif;
    font-size: 1rem;
    font-weight: bold;
    min-height: 64px;
    line-height: 1.5;
    opacity: .9;
    width: calc(100% - 80px);

    &-entity {
      color: @tsp-text-violet;
    }
    &-person {
      color: @tsp-login-main-blue;
    }
    &-value {
      color: @tsp-login-secondary-blue;
    }
  }
  &__cta {
    color: @tsp-login-main-blue;
    height: 28px;
    line-height: 1.75;
    margin-top: 12px;

    &-link {
      align-items: center;
      color: @tsp-login-main-blue;
      display: flex;
      font-family: 'ProductSansRegular', sans-serif;
      font-size: 1rem;
      font-weight: normal;
      text-decoration: none;
    }
    &-icon {
      margin-left: 5px;
    }
  }
}
