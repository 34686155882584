@import '_mixins';
@import '_variables';

.c-tsp-mat {
  &-checkbox {
    flex: 1 1 auto;
    margin-left: 15px;

    @media @tablet, @xs-desktop {
      margin-left: 4px;
    }

    .mdc-checkbox {
      flex-basis: 16px;
      height: 16px;
      width: 16px;
      .mdc-checkbox__background {
        height: 16px;
        width: 16px;
      }
      .mat-mdc-checkbox-frame {
        border-width: 0;
      }

      .mat-mdc-checkbox-ripple,
      .mdc-checkbox__ripple {
        display: none !important;
      }
    }

    &.mat-mdc-checkbox
      .mdc-checkbox
      .mdc-checkbox__native-control:checked
      ~ .mdc-checkbox__background,
    &.mat-mdc-checkbox
      .mdc-checkbox
      .mdc-checkbox__native-control:indeterminate
      ~ .mdc-checkbox__background,
    &.mat-mdc-checkbox
      .mdc-checkbox
      .mdc-checkbox__native-control[data-indeterminate='true']
      ~ .mdc-checkbox__background {
      background-color: @tsp-primary-violet !important;
      &:hover {
        .mdc-checkbox__background {
          border: 0;
        }
      }
    }

    .mdc-checkbox__background {
      border: 2px solid @tsp-control-border-active !important;
    }
  }

  &-radio-button {
    flex: 1 1 auto;
    margin: 0 15px;

    &--with-label {
      &:first-child {
        margin-left: 0;
        margin-top: 10px;
      }
    }

    .mdc-radio {
      .mdc-radio__outer-circle {
        border-color: @tsp-primary-violet !important;
      }

      .mdc-radio__inner-circle {
        border-color: @tsp-primary-violet !important;
      }
      .mat-radio-ripple,
      .mdc-radio__background::before {
        display: none;
      }
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
  }

  &-date-select {
    margin-top: 0 !important;

    @media @tablet, @xs-desktop {
      margin-top: -27px !important;
      padding-bottom: 22px;
    }

    &__input {
      border-radius: 10px;
      .default-text();
      height: 37px !important;
      outline: none;
      padding: 10px;
      width: 100%;
    }
  }

  &-input {
    background-color: @tsp-control-background;
    border: none;
    border-radius: 10px;
    .default-text();
    height: 56px !important;
    overflow: hidden;
    outline: none;
    padding-left: 10px;
    text-overflow: ellipsis;
    width: 100%;
  }

  &-form-field {
    display: flex !important;
    flex-direction: column;
    flex: 1 1 auto;
    margin: 0 15px;

    &--search-by-id {
      .mat-mdc-form-field-infix {
        display: flex;
      }
    }

    &__row {
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: space-between;

      @media @xs-desktop {
        align-items: flex-start;
        flex-direction: row;
        margin-top: 15px;
        margin-bottom: 22px;
      }
    }

    &-input {
      &::-webkit-input-placeholder {
        color: fade(@black, 42%);
      }

      &::-moz-placeholder {
        color: fade(@black, 42%);
      }

      &:-ms-input-placeholder {
        color: fade(@black, 42%);
      }

      &:-moz-placeholder {
        color: fade(@black, 42%);
      }

      &-prefix {
        color: @tsp-icon-grey;
        margin-left: 0.5rem;
      }
    }

    &-autocomplete {
      display: flex !important;
      margin: 0 !important;
    }

    &__popup-checkbox {
      flex: 1 1 auto;
      margin: 0 15px;
    }

    &__label {
      align-self: flex-start;
      .default-text(@color: fade(@tsp-text-dark, 50%), @size: 0.8rem);
      padding-bottom: 5px;
      transform: perspective(100px);
    }

    .mdc-line-ripple {
      display: none;
    }

    .mat-mdc-form-field-wrapper {
      padding-bottom: 0;
      width: 100%;
    }

    .mat-mdc-form-field-underline {
      display: none;
    }

    .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline {
      display: none;
    }

    .mat-mdc-select-arrow-wrapper {
      margin-left: 20px;
      transform: none !important;
    }

    .btn-outline-primary:not(:disabled):not(.disabled).active,
    .btn-outline-primary:not(:disabled):not(.disabled):active,
    .show > .btn-outline-primary.dropdown-toggle {
      background-color: @tsp-control-background;
      border: 0;
      color: @tsp-secondary-violet;
    }

    &-option {
      &:focus:not(.mat-mdc-option-disabled),
      &:hover:not(.mat-mdc-option-disabled) {
        background-color: @tsp-list-background;
        color: @tsp-primary-violet;
      }

      .mdc-list-item__primary-text {
        align-items: center;
        display: flex;
        .default-text() !important;
        padding: 3px 0;
      }
    }

    &--filter {
      &.mat-mdc-form-field {
        margin: 0;
        max-width: 190px;
      }
      .mat-mdc-text-field-wrapper {
        background-color: @tsp-control-background;
        border-radius: 10px;
        overflow: visible;

        .mat-mdc-form-field-infix {
          padding: 10px 0 !important;
          min-height: 40px;
        }
        .mdc-floating-label {
          top: 6px;
        }
        .mat-mdc-form-field-focus-overlay {
          display: none;
        }
        .mat-mdc-select-placeholder,
        .mat-mdc-select-value-text,
        .mat-mdc-input-element {
          .default-text(@size: 0.8rem);
        }
        .mat-mdc-form-field-icon-suffix {
          color: fade(@tsp-control-border-active, 75%);
          svg {
            height: 20px;
            width: 20px;
          }
        }
      }
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }

    &--with-label {
      .mat-mdc-text-field-wrapper {
        background-color: @tsp-control-background;
        border-radius: 10px;
        overflow: visible;

        .mat-mdc-form-field-infix {
          padding: 15px 0;
        }
        .mdc-floating-label {
          left: -6px !important;
          top: 2px !important;
        }
        .mat-mdc-form-field-focus-overlay {
          display: none;
        }
        .mat-mdc-form-field-icon-suffix {
          align-items: center;
          color: fade(@tsp-control-border-active, 75%);
          display: flex;
          font-size: 1.2rem;
          gap: 5px;
          margin-right: 15px;

          .mat-mdc-icon-button {
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .mat-mdc-form-field-hint-wrapper,
      .mat-mdc-form-field-error-wrapper {
        .default-text(@size: 0.65rem);
        line-height: 12px;
        margin-top: -5px;
        padding: 0;
      }
    }

    &--outline {
      .mat-mdc-text-field-wrapper {
        background-color: transparent;
        border: 1px solid @tsp-control-border;
        border-radius: 10px;
        overflow: visible;
      }

      .mat-mdc-form-field-hint-wrapper,
      .mat-mdc-form-field-error-wrapper {
        .default-text(@size: 0.8rem);
        line-height: 12px;
        margin-top: -4px;
        margin-left: 11px;
        padding: 0;
      }

      .c-tsp-mat-input {
        background-color: transparent;
        border: 1px solid @tsp-control-border;
      }

      &.mat-form-field-invalid {
        .mat-mdc-text-field-wrapper {
          border-color: @red-pink;
        }

        .c-tsp-mat-input {
          border-color: @red-pink;
        }
      }
    }
  }

  &-multiple-select {
    border-radius: 2px;

    &-arrow-none {
      .mat-mdc-select-arrow {
        border: none !important;
      }
    }

    .mat-mdc-select-arrow {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid;
      color: @tsp-secondary-violet;
    }

    &.ng-dirty {
      background-color: @tsp-primary-violet;
      color: @white;

      .mat-mdc-select-value {
        color: @white;
      }

      .mat-mdc-select-arrow {
        color: @white !important;
      }
    }

    &__option {
      &.mat-mdc-option {
        font-size: 0.8rem;
        height: auto;
        line-height: 16px;
      }

      &:hover {
        background: @tsp-list-background !important;
        color: @tsp-primary-violet;
      }

      &.mat-mdc-selected.mat-mdc-active {
        background: @tsp-list-background !important;
        color: @black !important;
      }

      .mat-pseudo-checkbox {
        background-color: @tsp-control-border;
        border: 0;
        border-radius: 2px;
        height: 13.5px;
        width: 13.5px;

        &.mat-pseudo-checkbox-checked {
          background-color: @tsp-primary-violet;

          &::after {
            border-left: 2px solid currentColor;
            height: 5px;
            left: 2px;
            opacity: 1;
            transform: rotate(-45deg);
            top: 2px;
            width: 8px;
          }
        }

        .mat-mdc-option-ripple {
          display: none;
        }

        &:hover:not(.mat-pseudo-checkbox-checked) {
          background-color: @white;
          border: solid 1px fade(@tsp-primary-violet, 88%);
        }
      }

      .mdc-list-item__primary-text {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        gap: 8px;
        padding: 10px 0;
      }
    }
  }

  &-popup-form {
    margin: 0 -25px -27px -25px;

    @media @tablet, @xs-desktop {
      margin: 40px -25px -27px -25px;
    }

    justify-content: flex-end;

    &.mat-mdc-dialog-actions {
      align-items: center;
      justify-content: flex-end;
      padding: 27px 10px 26px 10px;

      @media @xs-mobile, @s-mobile, @mobile {
        padding: 10px 0;
        justify-content: center;
      }

      @media @tablet, @xs-desktop {
        padding: 27px 55px 26px 40px;
      }
    }

    &--advanced-search {
      margin: 25px -24px;
      align-items: center;
    }

    &__item {
      &-wrapper {
        display: flex;
        flex: 0 0 25%;
      }
    }

    &__button {
      border-radius: 14px !important;
      height: 45px !important;
      padding: 10px !important;
      .transition(@duration: 0.3s);
      min-width: 80px;

      &-wrapper {
        justify-content: space-between;

        &-bid-close {
          justify-content: space-around !important;
        }
      }

      &--additional-action {
        background-color: @yellow-green !important;
        color: @main-blue !important;
        flex: 0 1 auto;
        margin-right: 15px;

        &:hover {
          background-color: @green-mint !important;
          color: @main-blue-darken !important;
        }
      }
      &--close {
        background-color: @tsp-list-background !important;
        color: @tsp-icon-violet-hover !important;
        flex: 0 1 auto;
        margin-right: 15px;

        &:hover {
          background-color: @tsp-button-background-hover !important;
          color: @tsp-icon-violet-hover !important;
        }
      }
      &--remove {
        background-color: @red-coral !important;
        color: @light-grey !important;
        flex: 0 1 auto;
        margin-right: 15px;

        &:hover {
          background-color: @red-pink !important;
          color: @light-grey !important;
        }
      }
      &--create {
        background-color: @tsp-primary-violet !important;
        color: @white !important;
        flex: 0 1 auto;

        &:hover {
          background-color: @tsp-icon-violet-hover !important;
          color: @white !important;
        }
      }
      &--create-open {
        border: 1px solid @tsp-primary-violet !important;
        color: @tsp-primary-violet !important;
        flex: 0 1 auto;

        &:hover {
          background-color: @tsp-icon-violet-hover !important;
          color: @white !important;
        }
      }

      &--disabled {
        background-color: @tsp-tag-light-hover-background !important;

        &:hover {
          background-color: @tsp-tag-light-hover-background !important;
        }
      }
    }

    &__message {
      flex: 1 0;
      .muted-text(@color: @red-pink);
      margin-right: auto;

      &-icon {
        margin-right: 5px;
      }
    }
  }

  &-dialog-container {
    .mat-mdc-dialog-surface {
      padding: 40px 25px 27px 25px;
    }
  }

  &-dialog-content {
    &.mat-mdc-dialog-content {
      max-height: 45vh;
      padding-top: 5px;

      @media @xs-mobile, @s-mobile {
        max-height: 50vh;
      }

      @media @mobile {
        max-height: 60vh;
      }

      @media @tablet, @xs-desktop {
        max-height: calc(70vh - 120px);
      }
    }
  }
}
