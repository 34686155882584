@import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
@import '@fortawesome/fontawesome-free/css/solid.min.css';
@import '@fortawesome/fontawesome-free/css/regular.min.css';
@import '@fortawesome/fontawesome-free/css/brands.min.css';

@import '@egjs/flicking-plugins/dist/arrow.min.css';
@import '@egjs/flicking-plugins/dist/pagination.min.css';

@import '~bootstrap/dist/css/bootstrap.min.css';
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '~intl-tel-input/build/css/intlTelInput.css';

@import '~simple-line-icons/dist/styles/simple-line-icons.css';
@import '~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';
@import 'ngx-toastr/toastr';
@import '~nouislider/dist/nouislider.min.css';

@import 'styles/_buttons.less';
@import 'styles/_dialogs.less';
@import 'styles/_forms.less';
@import 'styles/_notifications.less';
@import 'styles/_search-highlight.less';
@import 'styles/_popups.less';
@import 'styles/_tags.less';
@import 'styles/_angular-material.less';
@import 'styles/_icons.less';
@import 'styles/_fonts.less';
@import 'styles/_variables.less';

html,
body {
  height: 100%;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.lock-scrolling {
  overflow: hidden;
}

// Sleekplan alignment
.i-sl-wrapper.left {
  left: initial !important;
  right: 15px !important;
}

// Setting main font for whole project and material elements
* {
  font-family: @main-font;
}
[class^='mat-'],
[class^='mdc-'] {
  font-family: @main-font !important;
  letter-spacing: 0;
}
