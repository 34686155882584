.skype {
  background: url(../assets/skype-3-fontello.svg) no-repeat;
  background-size: contain;
}

.mail {
  background: url(../assets/mail-alt-fontello.svg) no-repeat center;
  background-size: contain;
}

.djinni {
  background: url(../assets/djinni.svg) no-repeat;
  background-size: contain;
}

.linkedIn {
  background: url(../assets/linkedin-6-fontello.svg) no-repeat;
  background-size: contain;
}

.phone {
  background: url(../assets/phone-call.svg) no-repeat;
  background-size: contain;
}

.globe {
  background: url(../assets/grid-world.svg) no-repeat;
  background-size: contain;
}

.github {
  background: url(../assets/github.svg) no-repeat;
  background-size: contain;
}

.facebook {
  background: url(../assets/facebook.svg) no-repeat;
  background-size: contain;
}

.twitter {
  background: url(../assets/twitter.svg) no-repeat center;
  background-size: contain;
}

.stack-overflow {
  background: url(../assets/stack-overflow.svg) no-repeat;
  background-size: contain;
}

.work_ua {
  background: url(../assets/workua.svg) no-repeat;
  background-size: contain;
}

.rabota_ua {
  background: url(../assets/rabotaua.svg) no-repeat;
  background-size: contain;
}

.hh {
  background: url(../assets/head_hunter.png) no-repeat;
  background-size: contain;
}

.grc {
  background: url(../assets/grc.svg) no-repeat;
  background-size: contain;
}
